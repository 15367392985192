import NavBar from "./components/NavBar/NavBar.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    NavBar
  },
  data() {
    return {
      loggedInStatus: localStorage.getItem("LoggedIn_Status")
    };
  },
  computed: {
    ...mapGetters({
      getLoggedIn: "getLoggedIn",
      getUser: "getUser"
    })
  }
};
