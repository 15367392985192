
import Loader from "../../components/Loader/Loader.vue";
import SignInImage from "../../components/SignInImage/SignInImage.vue";
import SignInBackground from '../../components/SignInBackground/SignInBackground.vue'
import {mapGetters, mapActions} from 'vuex';

export default {
name: "view-profile-page",
  components: {
    SignInImage,
    Loader,
    SignInBackground
  },

computed: {
  // ...mapGetters(['resendEmailId'])
  ...mapGetters({
      getResendMessageStatus: 'getResendMessageStatus',
      getResendSuccessMessage: 'getResendSuccessMessage',
      getLoader: 'getLoader',
    })
},

  methods: {
    resendVerificationmail() {
     let email = this.$route.params.email;
     let sendRequest= {
       emailId: email
     }
     this.$store.dispatch('resendVerificationEmail',sendRequest);
    }
  }

}