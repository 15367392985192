import { mapGetters, mapActions } from "vuex";
import SignInImage from "../../components/SignInImage/SignInImage.vue";
import LogIn from "../../components/SignIn-SignUp/LogIn.vue";
import Loader from "../../components/Loader/Loader.vue";
import AlertDialog from "../../components/AlertDialog/AlertDialog.vue";
import SignInBackground from "../../components/SignInBackground/SignInBackground.vue";

export default {
  name: "SignIn",
  components: {
    SignInImage,
    LogIn,
    Loader,
    AlertDialog,
    SignInBackground
  },
  created() {
    let loginStatus = localStorage.getItem("LoggedIn_Status");
    if (loginStatus) {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.linkedinSign();
  },

  computed: {
    ...mapGetters({
      getLoader: "getLoader",
      getAlertDialogStatus: "getAlertDialogStatus"
    })
  },

  methods: {
    linkedinSign() {
      let parsedUrl = this.$route.query;
      let code = this.$route.query.code;
      let state = this.$route.query.state;

      if (code && state === "authLinkedIn") {
        let sendRequest = {
          loginSource: "Linkedin",
          logonId: "",
          password: "",
          oauthUri: process.env.VUE_APP_LINK,
          accessToken: code
        };
        this.$store.dispatch("authenticate", sendRequest);
      }
    }
  }
};
