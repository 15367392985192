import axiosCall from "../../api/axios";
import router from "../../router";

export default {
  name: "PopUp",
  data() {
    return {
      contentType: this.$route.params.contentType,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      }
    };
  },
  props: [
    "message",
    "draftMessageStatus",
    "deleteId",
    "deleteState",
    "deleteCreatedBy",
    "deleteStatue",
    "saveMessage",
    "saveMessageStatus"
  ],

  methods: {
    ok() {
      router.push({ path: `/page/${this.contentType}` });
    },
    close() {
      if (this.deleteStatue) {
        this.$emit("changePopup", "closed");
      } else {
        window.location.reload();
      }
    },
    dashboard() {
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/dashboard";
    },

    closeModal() {
      window.location.reload();
    },

    deleteContent(id, createdBy, state) {
      id = this.deleteId;
      state = this.deleteState;
      if (state === "DRAFT") {
        var url =
          process.env.VUE_APP_HOST +
          "content/api/v1/influencer/draft/discard/" +
          id;
        if (localStorage.getItem("assistant") == "true") {
          var inflncrId = localStorage.getItem("influencerId");
          url =
            process.env.VUE_APP_HOST +
            "content/api/v1/influencer/draft/discard/" +
            id +
            "?influencerId=" +
            inflncrId;
        }
        axiosCall.deleteRequest(url, (response, type) => {
          if (type === "success") {
            this.getLoader = false;
            this.$emit("changePopup", "deleted");
          }
        });
      } else {
        var url = process.env.VUE_APP_HOST + "content/api/v1/influencer/" + id;
        if (localStorage.getItem("assistant") == "true") {
          var inflncrId = localStorage.getItem("influencerId");
          url =
            process.env.VUE_APP_HOST +
            "content/api/v1/influencer/" +
            id +
            "?influencerId=" +
            inflncrId;
        }
        axiosCall.deleteRequest(url, (response, type) => {
          if (type === "success") {
            this.getLoader = false;
            this.$emit("changePopup", "deleted");
          }
        });
      }
    }
  }
};
