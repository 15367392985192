import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router";
import axiosCall from "./../api/axios";
Vue.use(Vuex);
Vue.use(router);
const store = new Vuex.Store({
  state: {
    allExpertiseList: [],
    accessToken: localStorage.getItem("access_token"),
    storageUrl: "",
    counter: 0,
    errorStatus: false,
    errorMessage: "",
    alertDialogStatus: false,
    resendSuccessMessage: "",
    resendMessageStatus: false,
    loader: false,
    confirmMessage: false,
    loggedIn: false,
    email: {
      emailId: ""
    },
    user: {},
    authenticate: {},
    token: {
      access_token: "",
      refresh_token: "",
      expires_in: ""
    },
    profile: {
      id: "",
      firstName: "",
      lastName: "",
      profileUrl: "",
      email: "",
      title: "",
      organisation: "",
      tagLine: "",
      phoneNumber: "",
      yob: "",
      bio: "",
      achievements1: "",
      achievements2: "",
      achievements3: "",
      expertise: [],
      services: [],
      twitterUrl: "",
      facebookUrl: "",
      googleUrl: "",
      linkedinUrl: "",
      instagramUrl: "",
      websiteUrl: "",
      photo: null || File,
      achievements: []
    },
    influencerExpertiseId: []
  },
  mutations: {
    setToken(state, res) {
      this.state.token.access_token = localStorage.getItem("access_token");
      this.state.token.refresh_token = localStorage.getItem("refresh_token");
      this.state.token.expires_in = localStorage.getItem("expires_in");
    },
    setErrorAuthenticate(state, value) {
      state.authenticate = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    logout() {
      localStorage.clear();
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/";
    },
    setLoginEmailId(state, payload) {
      this.state.email.emailId = payload.logonId;
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href =
        baseUrl + "/verify-email" + "/" + this.state.email.emailId;
    },
    setErrorStatus(state, value) {
      state.errorStatus = value;
    },
    setErrorMessage(state, value) {
      state.errorMessage = value;
    },
    setLoader(state, value) {
      state.loader = value;
    },
    setConfirmMessge(state, value) {
      state.confirmMessage = value;
    },
    setAlertDialogStatus(state, value) {
      state.alertDialogStatus = value;
    },
    setResendSuccessMessage(state, value) {
      state.resendSuccessMessage = value;
    },
    setResendMessageStatus(state, value) {
      state.resendMessageStatus = value;
    },
    setLoggedIn(state, value) {
      state.loggedIn = value;
    },
    setProfileInfo(state, data) {
      this.state.profile.id = data.id;
      this.state.profile.bio = data.bio;
      this.state.profile.twitterUrl = data.twitterUrl;
      this.state.profile.facebookUrl = data.facebookUrl;
      this.state.profile.googleUrl = data.googleUrl;
      this.state.profile.linkedinUrl = data.linkedinUrl;
      this.state.profile.instagramUrl = data.instagramUrl;
      this.state.profile.websiteUrl = data.websiteUrl;
      this.state.profile.firstName = data.firstName;
      this.state.profile.lastName = data.lastName;
      this.state.profile.profileUrl = data.profileUrl;
      this.state.profile.email = data.mailId;
      this.state.profile.title = data.title;
      this.state.profile.organisation = data.organisation;
      this.state.profile.tagLine = data.tagLine;
      this.state.profile.phoneNumber = data.phoneNumber;
      this.state.profile.yob = data.yob;
      this.state.profile.achievements = data.achievements;
      if (
        this.state.profile.achievements &&
        this.state.profile.achievements.length > 0
      ) {
        this.commit(
          "setnfluencersAchievements",
          this.state.profile.achievements
        );
      }
      this.commit("setInfluencerServices", data);
      if (data.expertise != null) {
        data.expertise.forEach(item => {
          if (item) {
            if (!this.state.influencerExpertiseId.includes(item.id)) {
              this.state.influencerExpertiseId.push(item.id);
            } else {
              this.state.influencerExpertiseId = this.state.influencerExpertiseId.filter(
                filterItem => filterItem !== item
              );
            }
          }
        });
      }
      this.state.profile.expertise = data.expertise;
    },
    setAllExpertise(state, data) {
      let expertiseList = [];
      data.responseObject.map(function(value, key) {
        expertiseList.push(value);
      });
      this.state.allExpertiseList = expertiseList;
    },
    setAccessToken(state, data) {
      this.state.accessToken = data;
    },
    setProfilePhotoUrl(state, profileUrl) {
      this.state.storageUrl = profileUrl;
      this.state.profile.profileUrl = profileUrl.split("?")[0];
    },
    setInfluencerServices(state, data) {
      let servicesList = [];
      if (data.services != null) {
        data.services.map(function(value, key) {
          servicesList.push(value);
        });
      }
      if (servicesList.length == 0) {
        servicesList = [
          {
            serviceDesc: "",
            serviceName: "",
            serviceValue: ""
          }
        ];
      }
      state.profile.services = servicesList;
    },
    setInfluencerExpertiseId(state, expertiseId) {
      state.influencerExpertiseId = expertiseId;
    },
    setInfluencerExpertise(state, expertise) {
      state.profile.expertise = expertise;
    },
    setFirstAndLastName(state, profile) {
      state.profile.firstName = profile.firstName;
      state.profile.lastName = profile.lastName;
    },
    setnfluencersAchievements(state, achievements) {
      state.profile.achievements1 = achievements[0];
      state.profile.achievements2 = achievements[1];
      state.profile.achievements3 = achievements[2];
    }
  },
  actions: {
    authenticate(state, payload) {
      this.commit("setLoader", true);
      axiosCall.postRequest(process.env.VUE_APP_HOST + "account/api/v1/login", 
      payload, (response) => {
          this.commit("authenticate", response, payload);
          let response1 = response.data.responseObject;
          if (response.data.apiResponseStatus.code === 1000) {
            localStorage.setItem(
              "access_token",
              response1.oauth2AccessToken.access_token
            );
            localStorage.setItem(
              "refresh_token",
              response1.oauth2AccessToken.refresh_token
            );
            localStorage.setItem(
              "expires_in",
              response1.oauth2AccessToken.expires_in
            );


            let d = new Date();
            d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();

            document.cookie =
              "AccessToken" +
              "=" +
              localStorage.getItem("access_token") +
              ";" +
              expires +
              ";domain=.peoplehum.com;path=/";
            this.commit("setToken", response);
            this.commit("setErrorMessage", "");
            this.commit("setLoader", false);
            this.dispatch("getSession");
           
          } else if (response.data.apiResponseStatus.code === 9016) {
            this.commit("setLoginEmailId", payload);
          } else {
            this.commit("setErrorMessage", response.data.apiResponseStatus.message);
            this.commit("setErrorStatus", response);
            this.commit("setLoader", false);
          }
      });
    },
    getSession(state) {
      var portalHeaders = {
        "Content-Type": "application/json",
        Authorization: "bearer"+" "+ localStorage.getItem("access_token")
      };
      var influencers = [];
      axiosCall.getRequest(process.env.VUE_APP_HOST + "account/api/v1/session",
      (response, type) => {
        if(type === "success"){
          let userRole = false;
          let influencerRole = false;
          let adminRole = false;
          let assistantRole = false;
          let roles = [];
          localStorage.setItem("userProfile", response.data.responseObject.profileUrl);
          localStorage.setItem("username", response.data.responseObject.username);
          localStorage.setItem("assistant", false);
          localStorage.setItem('profileStatus',response.data.responseObject.profileCompleted);
          this.commit("setUser", response.data.responseObject);
          document.cookie =
            "User=" + JSON.stringify(response.data.responseObject);
          roles = response.data.responseObject.roles;
          userRole = roles.includes(1);
          influencerRole = roles.includes(2);
          assistantRole = roles.includes(4);
          adminRole = roles.includes(3);
          // if auth is set, close the window
          if (this.auth) {
            window.close();
          } else if (influencerRole) {
            this.commit("setLoggedIn", true);
            localStorage.setItem("LoggedIn_Status", true);
            localStorage.setItem("lastName", response.data.responseObject.lastName);
            localStorage.setItem("firstName", response.data.responseObject.firstName);
            const parsedUrl = new URL(window.location.href);
            const baseUrl = parsedUrl.origin;

            if(localStorage.getItem("profileStatus") === "true"){
              window.location.href = baseUrl + "/dashboard";
            } else {
              window.location.href = baseUrl + "/profile";
            }
            
           
          } else if (assistantRole) {
            this.commit("setLoggedIn", true);
            localStorage.setItem("LoggedIn_Status", true);
            localStorage.setItem("LoggedIn_Assistant", true);

            const parsedUrl = new URL(window.location.href);
            const baseUrl = parsedUrl.origin;

            axiosCall.getRequest(process.env.VUE_APP_HOST + "account/api/v1/assistant/influencers",
            (response, type) => {
              if(type ==="success"){
                influencers = response.data.responseObject;
                if (influencers.length == 1) {
                  localStorage.setItem("assistant", true);
                  localStorage.setItem("influencerId", influencers[0].id);
                  localStorage.setItem("lastName", influencers[0].lastName);
                  localStorage.setItem("firstName", influencers[0].firstName);
                  localStorage.setItem(
                    "influencerProfileUrl",
                    influencers[0].profileUrl
                  );
                  localStorage.setItem(
                    "influencerUserName",
                    influencers[0].username
                  );
                  localStorage.setItem("LoggedIn_Assistant", false);
                  let parsedUrl = new URL(window.location.href);
                  let baseUrl = parsedUrl.origin;
                  window.location.href = baseUrl + "/dashboard";
                } else {
                  window.location.href = baseUrl + "/choose/delegate";
                }
              }
            });
          } else {
            this.commit("setAlertDialogStatus", true);
          }
        }

      })
    },

    // Social login-linkedIn
    loginWithLinkedIn() {
      let url;
      url =
        "https://www.linkedin.com/oauth/v2/authorization" +
        "?response_type=code&client_id=" +
        "86vkb5963hyjwl";
      url += "&redirect_uri=" + process.env.VUE_APP_LINK;
      url += "&state=" + "authLinkedIn";
      url += "&scope=" + encodeURIComponent("r_liteprofile r_emailaddress w_member_social");
      window.location.href = url;
    },

    // ResendVerificationEmail
    resendVerificationEmail(state, payload) {
      this.commit("setLoader", true);

      axiosCall.postRequest(
        process.env.VUE_APP_HOST + "account/api/v1/resend-verification",
        payload,
        (response) => {
          if (response.data.apiResponseStatus.code === 1000) {
            const message = "Please check your email to verify. ";
            this.commit("setResendMessageStatus", true);
            this.commit("setResendSuccessMessage", message);
            this.commit("setLoader", false);
          } else {
            this.commit(
              "setResendSuccessMessage",
              response.data.apiResponseStatus.code
            );
            this.commit("setResendMessageStatus", false);
            this.commit("setLoader", false);
          }
        }
      );
    },

    getProfileInfo(context) {
      var portalHeaders = {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("access_token")
      };
      var url =
        process.env.VUE_APP_HOST + "account/api/v1/influencer/userDetail";
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");
        url =
          process.env.VUE_APP_HOST +
          "account/api/v1/influencer/userDetail?influencerId=" +
          inflncrId;
      }
      this.commit("setLoader", true);

      axiosCall.getRequest(url, (response, type) => {
        if (type === "success") {
          var profile = response.data.responseObject;
          if (response.data) {
            this.commit("setProfileInfo", profile);
            this.commit("setLoader", false);
          }
        }
      });
    },
    getAllExpertise(context) {

      axiosCall.getRequest(
        process.env.VUE_APP_HOST + "account/api/v1/expertise",
        (response, type) => {
          if (type === "success") {
            var profile = response.data.responseObject;
          if (response.data) {
            this.commit("setAllExpertise", response.data);
          }
          }
        }
      );
    },
    getPresignedUrlForImage(context, profileInfo, getters) {
      this.commit("setLoader", true);
      if (this.getters.isUserLoggedIn) {
        let accessToken = this.getters.getAccessToken;
      } else {
        this.$route.push({
          name: "SignIn"
        });
      }
      var url =
        process.env.VUE_APP_HOST + "content/api/v1/influencer/presignedurl";
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");
        url =
          process.env.VUE_APP_HOST +
          "content/api/v1/influencer/presignedurl?influencerId=" +
          inflncrId;
      }

      var presignedObject = [
        {
          mimeType: profileInfo.photo.type,
          numberOfPreSignedURLsRequired: 1,
          preSignedURLSource: "IMAGE"
        }
      ];

      axiosCall.postRequest(url, presignedObject, (response, type) => {
        if (type === "success") {
          if (response.data) {
            var storageUrl = response.data.responseObject[0].preSignedUrls[0];
            this.commit("setProfilePhotoUrl", storageUrl);
            this.dispatch("saveProfileImgToS3");
          }
        }
      });

    },
    saveProfileImgToS3(context) {
      let currentProfilePhotoUrl = this.state.storageUrl;
      let requestOptions = {
        headers: {
          "Content-Type": this.state.profile.photo.type,
          Accept: "text/plain, */*; q=0.01"
        }
      };
      let requestObj = this.state.profile.photo;
      axios
        .put(this.state.storageUrl, requestObj, requestOptions)
        .then(resp => {
          this.commit("setProfilePhotoUrl", currentProfilePhotoUrl);
          this.dispatch("saveProfileInfo");
        })
        .then(data => {});
    },
    saveProfileInfo(context) {
      this.commit("setLoader", true);
      this.state.influencerExpertiseId = this.state.influencerExpertiseId.filter(
        function(element) {
          return element !== undefined;
        }
      );
      let currentProfileInfo = {
        id: this.state.profile.id,
        bio: this.state.profile.bio,
        twitterUrl: this.state.profile.twitterUrl,
        facebookUrl: this.state.profile.facebookUrl,
        googleUrl: this.state.profile.googleUrl,
        linkedinUrl: this.state.profile.linkedinUrl,
        instagramUrl: this.state.profile.instagramUrl,
        websiteUrl: this.state.profile.websiteUrl,
        expertise: this.state.influencerExpertiseId,
        services: this.state.profile.services,
        firstName: this.state.profile.firstName,
        lastName: this.state.profile.lastName,
        profileUrl: this.state.profile.profileUrl,
        tagLine: this.state.profile.tagLine,
        title: this.state.profile.title,
        phoneNumber: this.state.profile.phoneNumber,
        organisation: this.state.profile.organisation,
        yob: this.state.profile.yob,
        achievements: this.state.profile.achievements
      };
      if (localStorage.getItem("assistant") == "true") {
        currentProfileInfo = {
          id: this.state.profile.id,
          bio: this.state.profile.bio,
          twitterUrl: this.state.profile.twitterUrl,
          facebookUrl: this.state.profile.facebookUrl,
          googleUrl: this.state.profile.googleUrl,
          linkedinUrl: this.state.profile.linkedinUrl,
          instagramUrl: this.state.profile.instagramUrl,
          websiteUrl: this.state.profile.websiteUrl,
          expertise: this.state.influencerExpertiseId,
          services: this.state.profile.services,
          firstName: this.state.profile.firstName,
          lastName: this.state.profile.lastName,
          profileUrl: this.state.profile.profileUrl,
          tagLine: this.state.profile.tagLine,
          title: this.state.profile.title,
          phoneNumber: this.state.profile.phoneNumber,
          organisation: this.state.profile.organisation,
          yob: this.state.profile.yob,
          achievements: this.state.profile.achievements,
          influencerId: localStorage.getItem("influencerId")
        };
      }
      let requestHeaders = {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("access_token")
      };
      axiosCall.postRequest(process.env.VUE_APP_HOST + "account/api/v1/editUserDetail",JSON.stringify(currentProfileInfo), (response, type) => {
        if (type === "success") {
          if (response.data) {
            this.commit("setProfileInfo", this.state.profile);
            localStorage.setItem(
              "influencerProfileUrl",
              this.state.profile.profileUrl
            );
            localStorage.setItem("lastName", this.state.profile.lastName);
            localStorage.setItem("firstName", this.state.profile.firstName);
            localStorage.setItem("userProfile", this.state.profile.profileUrl);
            this.commit("setConfirmMessge", true);
            this.dispatch("getProfileInfo");
          }
        }
      },null,requestHeaders);
    }
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    expertiseInfo(state) {
      return state.allExpertiseList;
    },
    influencerExpertise(state) {
      return state.profile.expertise;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    isUserLoggedIn(state) {
      return state.accessToken ? true : false;
    },
    getErrorStatus(state) {
      return state.errorStatus;
    },
    getErrorMessage(state) {
      return state.errorMessage;
    },
    getLoader(state) {
      return state.loader;
    },
    getConfirmMessage(state) {
      return state.confirmMessage;
    },
    getUser(state) {
      return state.user;
    },
    getErrorAuthenticate(state) {
      return state.authenticate;
    },
    getAlertDialogStatus(state) {
      return state.alertDialogStatus;
    },
    getResendSuccessMessage(state) {
      return state.resendSuccessMessage;
    },
    getResendMessageStatus(state) {
      return state.resendMessageStatus;
    },
    getLoggedIn(state) {
      return state.loggedIn;
    },
    getProfileUrl(state) {
      return state.profile.profileUrl;
    },
    influencerServices(state) {
      return state.profile.services;
    },
    influencerExpertiseId(state) {
      return state.influencerExpertiseId;
    }
  },
  modules: {
    namespaced: true
  }
});
export default store;
