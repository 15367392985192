import axios from "axios";
import axiosCall from "../../api/axios";
import Loader from "../../components/Loader/Loader.vue";
import PopUp from "../../components/PopUp/PopUp.vue";
import PreviewBlog from "../../components/PreviewBlog/previewBlog.vue";
import SideMenu from "../../components/SideMenu/SideMenu.vue";
import SubscribeFeedback from "../../components/Subscribe-FeedBack/SubscribeFeedback.vue";

export default {
  name: "listmedia",
  components: {
    SideMenu,
    PreviewBlog,
    Loader,
    PopUp,
    SubscribeFeedback
  },
  data() {
    return {
      hidePrevious: true,
      subscribe: false,
      feedback: false,
      hideNext: false,
      currentPage: 1,
      tooltip: false,
      user: {},
      editObject: null,
      popularContent: [],
      id: null,
      paging: {},
      content: {},
      filterSelected: 1,
      preview: false,
      previewBlogTitle: "",
      previewBlogDate: "",
      topContentStatus: false,
      influencerContentStatus: false,
      getLoader: false,
      deleteStatue: false,
      deleteId: "",
      deleteState: "",
      deleteCreatedBy: "",
      window: {
        width: 0,
        height: 0
      },
      request: {
        contentType: this.$route.params.contentType,
        states: ["IN_REVIEW", "IN_PROCESS", "REJECTED", "APPROVED"]
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      },
      contentType: this.$route.params.contentType,
      sendBy: {}
    };
  },
  mounted() {
    this.allSelected();
    this.topContent();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  watch: {
    $route(to, from) {
      window.location.reload();
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    updateSubscribe: function(e) {
      this.subscribe = e;
    },
    updateFeedback: function(e) {
      this.feedback = e;
    },
    updatePreview: function(e) {
      this.preview = e;
    },
    updatePopup(e) {
      this.deleteStatue = false;
      if (e == "deleted") {
        this.getLoader = true;
        if ((this.paging.totalItems - 1) % 10 == 0) {
          this.currentPage = this.currentPage - 1;
          if (this.currentPage != 0) {
            this.changeContent(this.currentPage);
          } else {
            this.currentPage = 1;
            this.changeContent(this.currentPage);
          }
        } else {
          this.changeContent(this.currentPage);
        }
      }
    },
    next: function() {
      this.currentPage = parseInt(
        document.getElementById("pageName").textContent
      );
      if (this.currentPage < this.paging.totalPages) {
        this.currentPage++;
        document.getElementById(
          "pageName"
        ).innerHTML = this.currentPage.toString();
        this.getLoader = true;
        this.changeContent(this.currentPage);
      }
    },
    previous: function() {
      this.currentPage = parseInt(
        document.getElementById("pageName").textContent
      );
      if (this.currentPage > 1) {
        this.currentPage--;
        document.getElementById(
          "pageName"
        ).innerHTML = this.currentPage.toString();
        this.getLoader = true;
        this.changeContent(this.currentPage);
      }
    },

    changeContent: function(page) {
      if (this.currentPage == 1) {
        this.hidePrevious = true;
        this.hideNext = false;
      } else if (this.currentPage == this.paging.totalPages) {
        this.hidePrevious = false;
        this.hideNext = true;
      } else {
        this.hidePrevious = false;
        this.hideNext = false;
      }
      document.getElementById(
        "pageName"
      ).innerHTML = this.currentPage.toString();
      page = page - 1;
      if (localStorage.getItem("assistant") == "true") {
        this.request.influencerId = localStorage.getItem("influencerId");
      }
      var url =
        process.env.VUE_APP_HOST +
        `content/api/v1/influencer/list?pageNo=${page}`;

      axiosCall.postRequest(url, this.request, this.callbackContent);
    },
    allSelected: function() {
      this.getLoader = true;
      this.filterSelected = 1;
      document.getElementById("pageName").innerHTML = "1";
      this.request = {
        contentType: this.contentType,
        states: ["IN_REVIEW", "IN_PROCESS", "REJECTED", "APPROVED", "DRAFT"]
      };
      if (localStorage.getItem("assistant") == "true") {
        this.request.influencerId = localStorage.getItem("influencerId");
      }

      axiosCall.postRequest(
        process.env.VUE_APP_HOST + `content/api/v1/influencer/list`,
        this.request,
        this.callback
      );
    },
    callback(response, type) {
      if (type === "success") {
        this.user = response.data.responseObject;
        this.paging = response.data.paging;
        if (response.data) {
          if (
            response.data.paging.totalPages == 1 ||
            response.data.paging.totalPages == 0
          ) {
            this.hideNext = true;
            this.hidePrevious = true;
            document.getElementById(
              "totalpages-text"
            ).innerHTML = `${response.data.paging.totalPages}`;
            if (response.data.paging.totalPages == 0) {
              document.getElementById("totalpages-text").innerHTML = "1";
            }
            this.getLoader = false;
          } else {
            this.hidePrevious = true;
            this.hideNext = false;
            document.getElementById(
              "totalpages-text"
            ).innerHTML = `${response.data.paging.totalPages}`;
            this.getLoader = false;
          }
        }
      }
    },
    callbackContent(response, type) {
      if (type === "success") {
        this.user = response.data.responseObject;
        this.paging = response.data.paging;
        if (this.paging.totalPages == this.currentPage) {
          this.hideNext = true;
        }
        document.getElementById(
          "totalpages-text"
        ).innerHTML = this.paging.totalPages.toString();
        this.getLoader = false;
      }
    },
    approvedSelected: function() {
      this.getLoader = true;
      this.filterSelected = 2;
      document.getElementById("pageName").innerHTML = "1";
      this.request = {
        contentType: this.contentType,
        states: ["APPROVED"]
      };
      if (localStorage.getItem("assistant") == "true") {
        this.request.influencerId = localStorage.getItem("influencerId");
      }
      axiosCall.postRequest(
        process.env.VUE_APP_HOST + `content/api/v1/influencer/list`,
        this.request,
        this.callbackApproved
      );
    },
    callbackApproved(response, type) {
      if (type === "success") {
        this.user = response.data.responseObject;
        this.paging = response.data.paging;
        if (
          response.data.paging.totalPages == 1 ||
          response.data.paging.totalPages == 0
        ) {
          this.hideNext = true;
          this.hidePrevious = true;
          document.getElementById(
            "totalpages-text"
          ).innerHTML = `${response.data.paging.totalPages}`;
          if (response.data.paging.totalPages == 0) {
            document.getElementById("totalpages-text").innerHTML = "1";
          }
          this.getLoader = false;
        } else {
          this.hidePrevious = true;
          this.hideNext = false;
          document.getElementById(
            "totalpages-text"
          ).innerHTML = `${response.data.paging.totalPages}`;
          this.getLoader = false;
        }
      }
    },
    rejectedSelected: function() {
      this.getLoader = true;
      document.getElementById("pageName").innerHTML = "1";
      this.filterSelected = 3;
      this.request = {
        contentType: this.contentType,
        states: ["REJECTED"]
      };
      if (localStorage.getItem("assistant") == "true") {
        this.request.influencerId = localStorage.getItem("influencerId");
      }
      axiosCall.postRequest(
        process.env.VUE_APP_HOST + `content/api/v1/influencer/list`,
        this.request,
        this.callbackRejected
      );
    },
    callbackRejected(response, type) {
      if (type === "success") {
        this.user = response.data.responseObject;
        this.paging = response.data.paging;
        if (
          response.data.paging.totalPages == 1 ||
          response.data.paging.totalPages == 0
        ) {
          this.hideNext = true;
          this.hidePrevious = true;
          document.getElementById(
            "totalpages-text"
          ).innerHTML = `${response.data.paging.totalPages}`;
          if (response.data.paging.totalPages == 0) {
            document.getElementById("totalpages-text").innerHTML = "1";
          }
          this.getLoader = false;
        } else {
          this.hidePrevious = true;
          this.hideNext = false;
          document.getElementById(
            "totalpages-text"
          ).innerHTML = `${response.data.paging.totalPages}`;
          this.getLoader = false;
        }
      }
    },
    inReviewSelected: function() {
      this.getLoader = true;
      document.getElementById("pageName").innerHTML = "1";
      this.filterSelected = 4;
      this.request = {
        contentType: this.contentType,
        states: ["IN_REVIEW"]
      };
      if (localStorage.getItem("assistant") == "true") {
        this.request.influencerId = localStorage.getItem("influencerId");
      }
      axiosCall.postRequest(
        process.env.VUE_APP_HOST + `content/api/v1/influencer/list`,
        this.request,
        this.callbackReview
      );
    },
    callbackReview(response, type) {
      if (type === "success") {
        this.user = response.data.responseObject;
        this.paging = response.data.paging;
        if (
          response.data.paging.totalPages == 1 ||
          response.data.paging.totalPages == 0
        ) {
          this.hideNext = true;
          this.hidePrevious = true;
          document.getElementById(
            "totalpages-text"
          ).innerHTML = `${response.data.paging.totalPages}`;
          if (response.data.paging.totalPages == 0) {
            document.getElementById("totalpages-text").innerHTML = "1";
          }
          this.getLoader = false;
        } else {
          this.hidePrevious = true;
          this.hideNext = false;
          document.getElementById(
            "totalpages-text"
          ).innerHTML = `${response.data.paging.totalPages}`;
          this.getLoader = false;
        }
      }
    },
    draftsSelected: function() {
      this.getLoader = true;
      this.filterSelected = 5;
      document.getElementById("pageName").innerHTML = "1";
      this.request = {
        contentType: this.contentType,
        states: ["IN_PROCESS", "DRAFT"]
      };
      if (localStorage.getItem("assistant") == "true") {
        this.request.influencerId = localStorage.getItem("influencerId");
      }
      axiosCall.postRequest(
        process.env.VUE_APP_HOST + `content/api/v1/influencer/list`,
        this.request,
        this.callbackDraft
      );
    },
    callbackDraft(response, type) {
      if (type === "success") {
        this.user = response.data.responseObject;
        this.paging = response.data.paging;
        if (
          response.data.paging.totalPages == 1 ||
          response.data.paging.totalPages == 0
        ) {
          this.hideNext = true;
          this.hidePrevious = true;
          document.getElementById(
            "totalpages-text"
          ).innerHTML = `${response.data.paging.totalPages}`;
          if (response.data.paging.totalPages == 0) {
            document.getElementById("totalpages-text").innerHTML = "1";
          }
          this.getLoader = false;
        } else {
          this.hidePrevious = true;
          this.hideNext = false;
          document.getElementById(
            "totalpages-text"
          ).innerHTML = `${response.data.paging.totalPages}`;
          this.getLoader = false;
        }
      }
    },

    myRowClickHandler(item) {
      let payload;
      if (this.contentType != "EBOOK") {
        this.preview = true;
        this.id = item.id;
      } else {
        this.id = item.id;
        axiosCall.getRequest(
          process.env.VUE_APP_HOST + "content/api/v1/url/" + item.id,
          this.callbackEbook,
          payload
        );
      }
    },
    callbackEbook(response, type) {
      if (type === "success") {
        if (response.data.responseObject != null) {
          window.open(response.data.responseObject, "_blank");
        } else {
          this.tooltip = true;
          setTimeout(() => {
            this.tooltip = false;
          }, 5000);
        }
      }
    },
    topContent(contentType) {
      contentType = this.$route.params.contentType;
      axiosCall.getRequest(
        process.env.VUE_APP_HOST + "content/api/v1/top_content/" + contentType,
        (response, type) => {
          if (type === "success") {
            this.popularContent = response.data.responseObject;
          }
        }
      );
    },
    editContent(id, createdBy, state) {
      var url = process.env.VUE_APP_HOST + "content/api/v1/influencer/" + id;
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");
        url =
          process.env.VUE_APP_HOST +
          "content/api/v1/influencer/" +
          id +
          "?influencerId=" +
          inflncrId;
      }
      axiosCall.getRequest(url, this.callbackEdit);
    },
    callbackEdit(response, type) {
      if (type === "success") {
        this.editObject = response.data.responseObject;
        this.$router.push({
          name: "AddContent",
          params: {
            edit: true,
            status: this.editObject.state,
            contentid: this.editObject.id,
            contentObject: this.editObject
          }
        });
      }
    },
    deleteContent(id, createdBy, state) {
      window.scrollTo(0, 0);
      this.deleteStatue = true;
      this.deleteId = id;
      this.deleteState = state;
      this.deleteCreatedBy = createdBy;
    }
  }
};
