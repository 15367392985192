import { mapGetters } from "vuex";
import axiosCall from "../../api/axios";
import PopUp from "../../components/PopUp/PopUp.vue";
import PreviewBlog from "../../components/PreviewBlog/previewBlog.vue";
import SubscribeFeedback from "../../components/Subscribe-FeedBack/SubscribeFeedback.vue";
import SideMenu from "../SideMenu/SideMenu.vue";
export default {
  name: "NavBar",
  components: {
    SideMenu,
    PopUp,
    PreviewBlog,
    SubscribeFeedback
  },
  data() {
    return {
      open: true,
      preview: false,
      debounceTime: 0,
      openMenu: false,
      searchDropdown: null,
      showSearchDropDown: false,
      openMobileSearch: true,
      draftMessageStatus: false,
      delegateStatus: false,
      getAlertDialogStatus: false,
      mutablesubscribe: false,
      mutablefeedback: false,
      message: "",
      searchText: "",
      loggedInStatus: localStorage.getItem("LoggedIn_Status"),
      loggedInAssistant: localStorage.getItem("LoggedIn_Assistant"),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      },
      request: {},
      image: "",
      imageset: false,
      window: {
        width: 0,
        height: 0
      },
      searchError: false,
      searchErrorTxt: ""
    };
  },

  computed: {
    ...mapGetters({
      getLoggedIn: "getLoggedIn",
      getUser: "getUser"
    })
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    if (localStorage.getItem("assistant") == "true") {
      this.image = localStorage.getItem("influencerProfileUrl");
      this.imageset = true;
      this.delegateStatus = true;
      this.loggedInAssistant = false;
    } else {
      this.image = localStorage.getItem("userProfile");
      this.imageset = true;
      this.delegateStatus = false;
    }
  },
  methods: {
    updatePreview: function(e) {
      this.preview = e;
    },
    setCookie(cname) {
      let cookieValue;
      cname = "User";
      const cookieArr = document.cookie.split(";");
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (cname == cookiePair[0].trim()) {
          cookieValue = JSON.parse(decodeURIComponent(cookiePair[1]));
          this.image = cookieValue.profileUrl;
          return cookieValue;
        }
      }
    },
    handleDropdown() {
      this.hideSearchDropDown();
    },
    debounce() {
      if (this.searchText.length >= 4) {
        clearTimeout(this.debounceTime);
        this.debounceTime = setTimeout(() => {
          this.getSearchResults();
        }, 500);
      }
    },
    getSearchResults() {
      if (this.delegateStatus == true) {
        this.request = {
          influencerId: localStorage.getItem("influencerId"),
          searchText: this.searchText
        };
      } else {
        this.request = {
          searchText: this.searchText
        };
      }
      axiosCall.postRequest(
        process.env.VUE_APP_HOST + `content/api/v1/influencer/search`,
        this.request,
        this.callback
      );
    },
    callback(response, type) {
      if (type === "success") {
        this.searchDropdown = response.data.responseObject;
        if (this.searchDropdown.length > 0) {
          this.showSearchDropDown = true;
          this.openMobileSearch = true;
          this.searchError = false;
          document.getElementById("search-here").style.borderBottom = "0";
      document.getElementById("search-here").style.borderRadius = "18px 18px 0px 0px";
        }
        if (this.searchDropdown.length === 0) {
          this.searchErrorTxt = "No result found";
          this.searchError = true;
          this.showSearchDropDown = true;
          this.openMobileSearch = true;
          document.getElementById("search-here").style.borderBottom = "0";
      document.getElementById("search-here").style.borderRadius = "18px 18px 0px 0px";
        }
      }
    },
    hideSearchDropDown() {
      this.showSearchDropDown = false;
      this.openMobileSearch = true;
      this.searchText = "";
      document.getElementById("search-here").style.border = "1px solid #3a3a3a";
      document.getElementById("search-here").style.borderRadius = "33px";
    },
    previewContent(item) {
      let payload;
      this.id = item.id;
      if (item.contentType != "EBOOK") {
        this.contentType = item.contentType;
        this.influencerContentStatus = true;
        this.topContentStatus = false;
        this.preview = true;
      } else {
        axiosCall.getRequest(
          process.env.VUE_APP_HOST + "content/api/v1/url/" + item.id,
          this.callbackEbook,
          payload
        );
      }
    },
    callbackEbook(response, type) {
      if (type === "success") {
        if (response.data.responseObject != null) {
          window.open(response.data.responseObject, "_blank");
        }
      }
    },
    openDropDownMenu() {
      this.open = false;
      this.openMenu = true;
    },
    closeDropDownMenu() {
      this.open = true;
      this.openMenu = false;
    },
    updateMenu: function(e) {
      this.closeDropDownMenu();
    },
    delegate() {
      
      this.$router.push({ name: "Delegate" });
    },
    addBlog() {
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/addcontent/BLOG";
    },
    addVideo() {
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/addcontent/VIDEO";
    },
    addEbook() {
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/addcontent/EBOOK";
    },
    gotoProfile() {
      
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/profile";
    },
    openSearchDropdown() {
        if (this.showSearchDropDown) {
          this.showSearchDropDown = false;
          this.openMobileSearch = true;
          this.searchText = "";
        }
        else {
          this.showSearchDropDown = true;
          this.openMobileSearch = false;
          if (document.getElementById("search-here")) {
            document.getElementById("search-here").style.borderBottom = "0";
            document.getElementById("search-here").style.borderRadius = "18px 18px 0px 0px";
          }
        }
      
    },
    closeSearchDropdown() {
      this.showSearchDropDown = false;
      this.openMobileSearch = true;
      this.searchText = "";
      document.getElementById("search-here").style.border = "1px solid #3a3a3a";
      document.getElementById("search-here").style.borderRadius = "33px";
    },
    gotoDasboard() {
      if(this.openMenu){
        this.closeDropDownMenu()
      }
      this.$router.push({ name: "Dashboard" });
    },
    menuClose() {
      this.closeDropDownMenu();
    },
    openSubscribe: function() {
      this.mutablesubscribe = true;
      this.openMenu = false;
    },
    openFeedback: function() {
      this.mutablefeedback = true;
      this.openMenu = false;
    },
    updateSubscribe: function(e) {
      this.mutablesubscribe = e;
    },
    updateFeedback: function(e) {
      this.mutablefeedback = e;
    },
    signout() {
      this.$store.commit("logout");
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
