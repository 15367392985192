<template>
  <div class="followers">
    <div class="body-section-followers">
      <SideMenu v-if="window.width > 1099"></SideMenu>
      <div class="view-followers-block">
        <div class="white-section-followers">
          <div class="view-followers">
            <h4 class="all-followers">All Followers</h4>
            <div v-if="this.nofollowers" class="no-followers-container">
              <h4 class="no-followers-text">
                You don't have any followers currently
              </h4>
            </div>
            <div class="followers-section">
              <div
                class="followers-data"
                v-for="(item, index) in follower"
                :key="index"
              >
                <img class="new-followers-img" :src="item.profileUrl" alt="" />
                <br />
                <span class="follower-name"
                  >{{ item.firstName }} {{ item.lastName }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "../components/SideMenu/SideMenu.vue";
import axios from "axios";
import axiosCall from "./../api/axios";

export default {
  name: "followers",
  components: {
    SideMenu,
  },
  data() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      follower: {},
      nofollowers: false,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.getFollowers();
  },
  methods: {
    getFollowers() {
      let payload;
      var url = process.env.VUE_APP_HOST + "content/api/v1/follow/followers";
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");
        url =
          process.env.VUE_APP_HOST +
          "content/api/v1/follow/followers" +
          "?influencerId=" +
          inflncrId;
      }
      axiosCall.getRequest(url, this.callback, payload);
    },
    callback(response, type) {
      if (type === "success") {
        let responseObject = response.data.responseObject;
        if (response.data.apiResponseStatus.code === 1000) {
          this.follower = responseObject;
          if (this.follower.length == 0) {
            this.nofollowers = true;
          }
        }
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.body-section-followers {
  display: flex;
  max-height: 100vh;
  min-height: 820px;
  overflow: hidden;
  background-color: #f7f7f7;
}
.all-followers {
  font-size: 20px;
  margin-left: 1.5em;
}
.view-followers-block {
  margin-top: 4em;
  padding-top: 2em;
  padding-left: 27px;
  padding-right: 22px;
  overflow-y: scroll;
  flex-grow: 1;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}
.white-section-followers {
  padding: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  font-family: "Noto Sans";
}
.no-followers-container {
  font-family: "Noto Sans";
  padding: 1px 1px 3px;
  margin-left: 1.9em;
}
.no-followers-text {
  font-size: 20px;
  color: #767676;
  margin-bottom: 0;
}
.followers-section {
  display: flex;
  flex-flow: wrap;
  padding: 0.5em;
}
.followers-data {
  text-align: center;
  width: 5em;
  padding: 0.5em;
}
img.new-followers-img {
  height: 2.76em;
  width: 2.76em;
  border-radius: 50%;
}
span.follower-name {
  font-family: "Noto Sans";
  font-size: 10px;
  color: #ae1536;
  word-break: break-word;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .body-section-followers {
    height: 100vh;
  }
  .white-section-followers {
    height: 100vh;
    max-height: 100%;
  }
}
</style>
