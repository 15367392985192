import axios from 'axios';
import SignInImage from "../../components/SignInImage/SignInImage.vue"
import Loader from "../../components/Loader/Loader.vue"
import SignInBackground from '../../components/SignInBackground/SignInBackground.vue'
import axiosCall from "../../api/axios";
export default {
  name: "DelegateConfirmation",
  components: {
    SignInImage,
    Loader,
    SignInBackground
  },
  data() {
    return {
      sucessMessageStatus: false,
      isLoading: false,
      errorMessage: ""
    };
  },
  mounted() {
    this.acceptInvite();
  },
  methods: {
    acceptInvite() {
      this.isLoading = true;
      let registerObject = this.prepareRequestObject();
      axiosCall.postRequest(process.env.VUE_APP_HOST + 'account/api/v1/assistant/verify/' + registerObject,
        {},(response,type)=> {
            if (response.data.apiResponseStatus.message === 'Success') {
              this.sucessMessageStatus = true;
              this.isLoading = false;
            }
            else if (response.data.apiResponseStatus.message === null) {
              this.sucessMessageStatus = false;
              this.errorMessage = "Invalid Link";
              this.isLoading = false;
            }
            else {
              this.sucessMessageStatus = false;
              this.errorMessage = "Invalid Link"
              this.isLoading = false;
            }
        });
    },
    prepareRequestObject() {
      const urlOpt = this.$route.params.otpInvite;
      let verifyObject = urlOpt;
      return verifyObject;
    }
  }
};
