import axiosCall from "../../api/axios";
import SignInImage from "../../components/SignInImage/SignInImage.vue";
import Loader from "../../components/Loader/Loader.vue";
import SignInBackground from "../../components/SignInBackground/SignInBackground.vue";

export default {
  name: "Emailverified",
  components: {
    SignInImage,
    Loader,
    SignInBackground
  },
  data() {
    return {
      sucessMessageStatus: false,
      isLoading: false,
      errorMessage: ""
    };
  },

  mounted() {
    this.verifiedEmail();
  },

  methods: {
    verifiedEmail() {
      this.isLoading = true;
      let registerObject = this.prepareRequestObject();
      axiosCall.postRequest(
        process.env.VUE_APP_HOST + "account/api/v1/verify-email",
        registerObject,
        (response)=> {
          if (response.data.apiResponseStatus.code === 1000) {
            this.sucessMessageStatus = true;
            this.isLoading = false;
          } else {
            this.sucessMessageStatus = false;
            this.errorMessage = "Invalid Link";
            this.isLoading = false;
          }
        }
      );
    },

    prepareRequestObject() {
      const urlOpt = this.$route.params.otpEmail;
      let verifyObject = {
        otp: urlOpt
      };
      return verifyObject;
    }
  }
};
