import SignInBackground from "../../components/SignInBackground/SignInBackground.vue";
import axios from "axios";
export default {
  name: "SignInBackground",
  components: {
   SignInBackground
  },
  data() {
    return {
      window:{
        width:0,
        height:0
      }
    };
  },
  created(){
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }, 
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  } 
}