import axios from "axios";
import axiosCall from "../../api/axios";
import Loader from "../../components/Loader/Loader.vue";

export default {
  name: "CommentPreview",
  components: {
    Loader
  },
  data() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      },
      user: {},
      commentId: this.contentId,
      firstUser: {},
      enterComment: "",
      childUser: {},
      parentUser: {},
      image: "",
      getLoader: false,
      mutablePreview: true,
      window: {
        width: 0,
        height: 0
      }
    };
  },
  props: [
    "commenterImage",
    "id",
    "contentTitle",
    "contentCoverImageUrl",
    "commenterFirstName",
    "commenterLastName",
    "description",
    "liked",
    "contentId",
    "updatedDate"
  ],

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  mounted() {
    this.getChildComments();
    if (localStorage.getItem("assistant") == "true") {
      this.image = localStorage.getItem("influencerProfileUrl");
    } else {
      this.image = localStorage.getItem("userProfile");
    }
  },
  watch: {
    // contentId() {
    //   this.getChildComments();
    // },
    id() {
      this.getChildComments();
    }
  },

  methods: {
    closeModal() {
      this.mutablePreview = false;
      this.$emit("changePreview", this.mutablePreview);
    },

    getChildComments() {
      this.getLoader = true;
      var url =
        process.env.VUE_APP_HOST +
        `content/api/v1/comment/${this.id}?likeCheckRequired=false`;
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");
        url =
          process.env.VUE_APP_HOST +
          `content/api/v1/comment/${this.id}?likeCheckRequired=false` +
          "&influencerId=" +
          inflncrId;
      }
      axiosCall.getRequest(
        url,(response,type)=> {
          if(type === "success"){
            this.childUser = response.data.responseObject;
            this.getLoader = false;
          }
        }
      );
    },

    addcomment() {
      let addCommentObject = this.prepareRequestObject();

      axiosCall.postRequest(
        process.env.VUE_APP_HOST + "content/api/v1/comment",
        addCommentObject,
        this.callbackAddComment
      );
    },
    callbackAddComment(response, type) {
      if (type === "success") {
        if (response.data.apiResponseStatus.code === 1000) {
          this.enterComment = "";
          this.getChildComments();
        }
      }
    },

    prepareRequestObject() {
      let addCommentObject = {
        description: this.enterComment,
        parentId: this.id,
        type: "COMMENT"
      };
      if (localStorage.getItem("assistant") == "true") {
        addCommentObject = {
          description: this.enterComment,
          parentId: this.id,
          influencerId: localStorage.getItem("influencerId"),
          type: "COMMENT"
        };
      }
      return addCommentObject;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
