import axios from "axios";
import axiosCall from "../../api/axios";
export default {
  name: "previewBlog",
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      content: {},
      preview: true,
      mutablePreview: true,
      pluginVimeo: false,
      pluginYoutube: false,
      contentUrl: "",
      firstName: "",
      lastName: "",
      vimeoId: "",
      categoryName: "",
      blogImage: "",
      tagList: [],
      request: {
        contentType: this.contentType,
        states: ["IN_REVIEW", "IN_PROCESS", "REJECTED", "APPROVED"]
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      }
    };
  },
  props: ["id", "contentType", "influencerContentStatus", "topContentStatus"],

  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    closeModal() {
      this.mutablePreview = false;
      this.$emit("changePreview", this.mutablePreview);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.influencerContentStatus === true && !this.topContentStatus) {
      var url =
        process.env.VUE_APP_HOST + `content/api/v1/influencer/${this.id}`;
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");
        url =
          process.env.VUE_APP_HOST +
          `content/api/v1/influencer/${this.id}` +
          "?influencerId=" +
          inflncrId;
      }
      axiosCall.getRequest(url, (response, type) => {
        if (type === "success") {
          this.content = response.data.responseObject;
          if (response.data) {
            this.contentUrl = this.content.contentUrl;
            this.tagList = this.content.tags;
            this.firstName = this.content.influencerFirstName;
            this.lastName = this.content.influencerLastName;
            if (this.content.categories != null) {
              this.categoryName = this.content.categories[0].name.toUpperCase();
            }
            if (this.contentType == "BLOG") {
              this.blogImage = this.content.coverImageUrl;
            }
            if (this.content.contentSource === "VIMEO") {
              this.pluginVimeo = true;
              this.vimeoId =
                "https://player.vimeo.com/video/" +
                this.content.videoId +
                "?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=193917";
            }
            if (this.content.contentSource === "YOUTUBE") {
              this.pluginYoutube = true;
            }
          }
        }
      });
    }
    if (this.topContentStatus ) {
      axiosCall.getRequest(
        process.env.VUE_APP_HOST + `content/api/v1/${this.id}`,
        (response, type) => {
          if (type === "success") {
            this.content = response.data.responseObject;
          if (response.data) {
            this.contentUrl = this.content.contentUrl;
            this.tagList = this.content.tags;
            this.firstName = this.content.updatedBy;
            this.categoryName = this.content.categories[0].name.toUpperCase();
            if (this.contentType == "BLOG") {
              this.blogImage = this.content.coverImageUrl;
            }
            if (this.content.contentSource === "VIMEO") {
              this.pluginVimeo = true;
              this.vimeoId =
                "https://player.vimeo.com/video/" +
                this.content.videoId +
                "?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=193917";
            }
            if (this.content.contentSource === "YOUTUBE") {
              this.pluginYoutube = true;
            }
          }
          }
        }
      );
    }
  }
};
