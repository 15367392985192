import axiosCall from "../../api/axios";
import CommentPreview from "../../components/CommentPreview/CommentPreview.vue";
import Loader from "../../components/Loader/Loader.vue";
import SideMenu from "../../components/SideMenu/SideMenu.vue";

export default {
  name: "Comment",
  components: {
    SideMenu,
    CommentPreview,
    Loader
  },
  data() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      },
      user: {},
      allUser: {},
      commenterImage: "",
      id: "",
      contentTitle: "",
      contentCoverImageUrl: "",
      commenterFirstName: "",
      commenterLastName: "",
      showcontent: true,
      description: "",
      liked: false,
      contentId: "",
      onCommentStatus: false,
      updatedDate: "",
      window: {
        width: 0,
        height: 0
      },
      available: false,
      nearby: false,
      isLoading: false
    };
  },
  props: ["parentId", "itemObject", "routeTransfer"],
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getLatestComments();
  },
  mounted() {
    this.getLatestComments();
  },
  methods: {
    updatePreview: function(e) {
      this.onCommentStatus = e;
    },
    getLatestComments() {
      this.isLoading = true;
      let payload;
      var url = process.env.VUE_APP_HOST + "content/api/v1/comment/influencer";
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");
        url =
          process.env.VUE_APP_HOST +
          "content/api/v1/comment/influencer" +
          "?influencerId=" +
          inflncrId;
      }
      axiosCall.getRequest(url, this.callback, payload);
    },
    callback(response, type) {
      if (type === "success") {
        this.user = response.data.responseObject;
        if (this.user.length == 0) {
          this.showcontent = false;
        }
        this.allUser = response.data.responseObject;
        this.isLoading = false;
        if (this.parentId != undefined) {
            for (var i = 0; i < this.user.length; i++) {
              if (this.user[i].id === this.parentId) {
                this.myRowClickHandler(this.user[i], 'user-' + this.user[i].id);
              }
            }
        }
      }
    },
    myRowClickHandler(item, id) {
      this.id = item.id;
      this.contentId = item.contentId;
      this.commenterImage = item.commenterImage;
      this.contentCoverImageUrl = item.contentCoverImageUrl;
      this.liked = item.liked;
      this.description = item.description;
      this.contentTitle = item.contentTitle;
      this.commenterFirstName = item.commenterFirstName;
      this.commenterLastName = item.commenterLastName;
      this.updatedDate = item.updatedDate;
      this.onCommentStatus = true;
      this.remove(this.allUser);
      this.add(this.allUser, id);
    },
    remove(allUser) {
      for (var i = 0; i < allUser.length; i++) {
        document.querySelector("#user-" + allUser[i].id) &&
          document
            .querySelector("#user-" + allUser[i].id)
            .classList.remove("active");
      }
    },
    add(allUser, id) {
      for (var i = 0; i < allUser.length; i++) {
        if (id === "user-" + allUser[i].id) {
          document.querySelector("#user-" + allUser[i].id) &&
            document
              .querySelector("#user-" + allUser[i].id)
              .classList.add("active");
        }
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
