import SubscribeFeedback from "../../components/Subscribe-FeedBack/SubscribeFeedback.vue";
import axios from "axios";
export default {
  name: "SideMenu",
  components: {
    SubscribeFeedback
  },
  data() {
    return {
      selected: undefined,
      mutablesubscribe: false,
      mutablefeedback: false,
      firstName: '',
      lastName:'',
      image: '',
      imageset:false,
      headers: {
        Authorization: "Bearer "+ localStorage.getItem('access_token'),
      },
      window:{
        width:0,
        height:0
      }
    };
  },
  created(){
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    if (localStorage.getItem('assistant') == 'true') {
      this.image = localStorage.getItem('influencerProfileUrl');
      this.imageset = true;
      this.getName();
    }else {
      this.image = localStorage.getItem('userProfile');
      this.imageset = true;
      this.getName();
    }
    if (window.location.pathname == '/dashboard' || window.location.pathname == '/followers') {
      this.selected = 1;
    }
    if (window.location.pathname == '/page/BLOG' || window.location.pathname=='/addcontent/BLOG') {
      this.selected = 2;
      
    }
    if (window.location.pathname == '/page/VIDEO' || window.location.pathname=='/addcontent/VIDEO') {
      this.selected = 3;
    }
    if (window.location.pathname == '/page/EBOOK' || window.location.pathname=='/addcontent/EBOOK') {
      this.selected = 4;
    }
    if (window.location.pathname == '/comment') {
      this.selected = 5;
    }
  },
  methods: {
    getName() {
      this.firstName = localStorage.getItem('firstName');
      this.lastName = localStorage.getItem('lastName');
    },
    openDropDownMenu: function () {
      this.open = false;
      this.openMenu = true;
    },
    openSubscribe: function () {
      this.mutablesubscribe = true;
      this.$emit("changeSubscribe", this.mutablesubscribe);
    },
    openFeedback: function () {
      this.mutablefeedback = true;
      this.$emit("changeFeedback", this.mutablefeedback);
    },
    updateSubscribe: function (e) {
      this.mutablesubscribe = e;
    },
    updateFeedback: function (e) {
      this.mutablefeedback = e;
    },
    gotoProfile() {
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + '/profile';

    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    exportCloseMenu() {
      this.$emit("changeMenu", true);
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  } 
}