import Vue from "vue";
import VueRouter from "vue-router";
import DelegateConfirmation from "../views/DelegateConfirmation.vue";
// route level code-splitting
// this generates a separate chunk (survey.[hash].js) for this route
// which is lazy-loaded when the route is visited.

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignIn.vue")
  },
  {
    path: "/login",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignIn.vue")
  },
  {
    path: "/signup",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignIn.vue")
  },
  {
    path: "/signup/invite",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignIn.vue")
  },
  {
    path: "/forgotpassword",
    name: "Resetpasswordlink",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Resetpasswordlink.vue")
  },
  {
    path: "/resetpassword/:otpPaasword",
    name: "Resetpasswordfield",
    component: () =>
      import(
        /* webpackChunkName: "resetpassword" */ "../views/Resetpasswordfield.vue"
      )
  },
  {
    path: "/verify-email/:email",
    name: "Resendverificationmail",
    component: () =>
      import(
        /* webpackChunkName: "verify-email" */ "../views/Resendverificationmail.vue"
      )
  },
  {
    path: "/verified/:otpEmail",
    name: "Emailverified",
    component: () =>
      import(/* webpackChunkName: "verified" */ "../views/Emailverified.vue")
  },
  {
    path: "/profile",
    name: "ViewProfile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ViewProfile.vue")
  },
  {
    path: "/page/:contentType",
    name: "ListMedia",
    component: () =>
      import(
        /* webpackChunkName: "ListMedia" */ "../components/ListMedia/ListMedia.vue"
      )
  },
  {
    path: "/addcontent/:contentType",
    name: "AddContent",
    component: () =>
      import(
        /* webpackChunkName: "addContent" */ "../components/AddContent/AddContent.vue"
      ),
    props: true
  },
  {
    path: "/delegate",
    name: "Delegate",
    component: () =>
      import(/* webpackChunkName: "Delegate" */ "../views/Delegate.vue")
  },
  {
    path: "/choose/delegate",
    name: "DelegateChoice",
    component: () =>
      import(
        /* webpackChunkName: "DelegateChoice" */ "../views/DelegateChoice.vue"
      )
  },
  {
    path: "/invite/:otpInvite",
    name: "DelegateConfirmation",
    component: DelegateConfirmation
  },
  {
    path: "/comment",
    name: "Comment",
    component: () =>
      import(/* webpackChunkName: "comment" */ "../views/Comment.vue"),
    props: true
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  },
  {
    path: "/followers",
    name: "ViewFollower",
    component: () =>
      import(
        /* webpackChunkName: "viewfollowers" */ "../views/ViewFollower.vue"
      )
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
