import { email, required } from "vuelidate/lib/validators";
import axiosCall from "../../api/axios";
import SideMenu from "../../components/SideMenu/SideMenu.vue";

export default {
  name: "Delegate",
  components: {
    SideMenu
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      delegateEmail: "",
      delegates: [],
      assistants: [],
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      }
    };
  },
  mounted() {
    this.getDelegateList();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  validations: {
    delegateEmail: {
      required,
      email
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    addDelegate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        axiosCall.postRequest(
          process.env.VUE_APP_HOST +
            "account/api/v1/assistant/" +
            this.delegateEmail,
          {},
          this.callback
        );
      }
    },
    callback(response, type) {
      if (type === "success") {
        window.location.reload();
      }
    },
    getDelegateList() {
      let payload;
      axiosCall.getRequest(
        process.env.VUE_APP_HOST + "account/api/v1/assistant",
        this.callbackDelegate,
        payload
      );
    },
    callbackDelegate(response, type) {
      if (type === "success") {
        let assistants = response.data.responseObject.assistants;
        let delegates = response.data.responseObject.inviteMails;
        if (assistants && assistants.length > 0) {
          assistants = assistants.filter(assistant => { return assistant.mailId !== 'kmisquitta7@gmail.com' });
        }
        if (delegates && delegates.length > 0) {
          delegates = delegates.filter(delegate => { return delegate.mailId !== 'kmisquitta7@gmail.com' });
        }
        this.assistants = assistants
        this.delegates = delegates;
      }
    },
    removeDelegate(id, isAssistant) {
      axiosCall.deleteRequest(
        process.env.VUE_APP_HOST +
          "account/api/v1/assistant/" +
          id +
          "?isAssistant=" +
          isAssistant,
        (response, type) => {
          if (type === "success") {
            window.location.reload();
          }
        }
      );
    },
    callbackDelete(response, type) {
      if (type === "success") {
        this.router.go();
      }
    }
  }
};
