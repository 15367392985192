import axiosCall from "../../api/axios";
import SignInImage from "../../components/SignInImage/SignInImage.vue";
import Loader from "../../components/Loader/Loader.vue";
import router from "../../router";
import SignInBackground from "../../components/SignInBackground/SignInBackground.vue";
import {
  required
} from "vuelidate/lib/validators";

export default {
  name: "Resetpasswordfield",
  components: {
    SignInImage,
    Loader,
    SignInBackground
  },
  data() {
    return {
      newPassword: "",
      password: "",
      errorMessageStatus: false,
      errorMessage: "",
      isLoading: false,
      message: "",
      messageStatus: false,
      showPassword: false,
      showPasswordConfirm: false,
      foundError: false
    };
  },

  validations: {
    
    password:{required,isPasswordValid(password) {
          const regex = /^(?=.*[A-Z])[A-Za-z\d.@$!%*?_&]{6,24}$/;
          if (regex.test(password)) {
            return true;
          } else {
            return false;
          }
    }
    },
    newPassword:{required,isPasswordValid(password) {
          const regex = /^(?=.*[A-Z])[A-Za-z\d.@$!%*?_&]{6,24}$/;
          if (regex.test(password)) {
            return true;
          } else {
            return false;
          }
    }
    },
    
    
  },
  methods: {
    toggle() {
      this.showPassword = !this.showPassword;
      const togglePassword = document.querySelector("#togglePassword");
      const password = document.querySelector("#password");

      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
    },

    toggleConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;
      const togglePassword = document.querySelector("toggleConfirm");
      const password = document.querySelector("#confirmpassword");

      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
    },

    customValidation() {
      this.foundError = false;
      if (!this.newPassword || !this.password) {
        this.errorMessageStatus = true;
        this.messageStatus = false;
        this.foundError = true;
        //this.errorMessage = "Please enter password";
      }
      return this.foundError ? false : true;
    },

    submit() {
      this.$v.$touch();
      if (this.customValidation() && !this.$v.$invalid) {
        if (this.passwordCheck()) {
          this.isLoading = true;
          let verifyObject = this.prepareRequestObject();
          axiosCall.postRequest(
            process.env.VUE_APP_HOST + "account/api/v1/set-password",
            verifyObject,
            (response)=> {
              if (response.data.apiResponseStatus.code === 1000) {
                this.message = "Password changed successfully";
                this.messageStatus = true;
                this.errorMessageStatus = false;
                this.isLoading = false;
                setTimeout(function () {
                  router.push("/");
                }, 3000);

              } else if (response.data.apiResponseStatus.code === 9019) {
                this.errorMessageStatus = true;
                this.messageStatus = false;
                this.errorMessage = "Link is invalid";
                this.isLoading = false;
              } else if (response.data.apiResponseStatus.code === 9017) {
                this.errorMessageStatus = true;
                this.messageStatus = false;
                this.errorMessage =
                  "Password length should be 6 to 24 and should have atleast 1 capital letter."
                this.isLoading = false;
              } else {
                this.errorMessageStatus = true;
                this.messageStatus = false;
                this.errorMessage = response.data.apiResponseStatus.message;
                this.isLoading = false;
              }
            }
          );
        }
      }
    },

    passwordCheck() {
      let newPass = this.newPassword;
      let confirmPass = this.password;
      let foundError = false;
      this.errorMessageStatus = false;
      if (newPass !== confirmPass) {
        this.errorMessageStatus = true;
        this.errorMessage = "The entered passwords do not match";
        foundError = true;
      }
      return foundError ? false : true;
    },

    prepareRequestObject() {
      const urlOpt = this.$route.params.otpPaasword;
      let verifyObject = {
        otp: urlOpt,
        password: this.password
      };
      return verifyObject;
    }
  }
};
