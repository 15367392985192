var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reset-password-field-page"},[_c('SignInBackground'),_c('div',{staticClass:"auth-container"},[_c('div',{staticClass:"auth-widget"},[_c('SignInImage'),(_vm.isLoading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"reset-password-auth"},[_c('div',{staticClass:"email-reset-block password-reset-block"},[(_vm.errorMessageStatus)?_c('div',{staticClass:"reset-pass-error-msg"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.messageStatus)?_c('div',{staticClass:"reset-message"},[_vm._v(_vm._s(_vm.message))]):_vm._e(),(!_vm.messageStatus && !_vm.errorMessageStatus)?_c('form',{staticClass:"reset-password-field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],attrs:{"type":"password","placeholder":"New Password","id":"password"},domProps:{"value":(_vm.newPassword)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.submit.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.newPassword=$event.target.value}}}),_c('span',{staticClass:"p-viewer"},[(_vm.showPassword)?_c('i',{staticClass:"fa fa-eye",attrs:{"id":"togglePassword"},on:{"click":_vm.toggle}}):_vm._e(),(!_vm.showPassword)?_c('i',{staticClass:"fa fa-eye-slash",attrs:{"id":"togglePassword"},on:{"click":_vm.toggle}}):_vm._e()]),(!_vm.$v.newPassword.required && _vm.$v.newPassword.$dirty)?_c('div',{staticClass:"invalid"},[_vm._v(" Password is required! ")]):_vm._e(),(
                !_vm.$v.newPassword.isPasswordValid &&
                _vm.$v.newPassword.required &&
                _vm.$v.newPassword.$dirty
              )?_c('div',{staticClass:"invalid"},[_vm._v(" Password length should be 6 to 24 and should have atleast 1 capital letter. ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","placeholder":"Confirm Password","id":"confirmpassword"},domProps:{"value":(_vm.password)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.submit.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"p-viewer"},[(_vm.showPasswordConfirm)?_c('i',{staticClass:"fa fa-eye",attrs:{"id":"toggleConfrim"},on:{"click":_vm.toggleConfirm}}):_vm._e(),(!_vm.showPasswordConfirm)?_c('i',{staticClass:"fa fa-eye-slash",attrs:{"id":"toggleConfirm"},on:{"click":_vm.toggleConfirm}}):_vm._e()]),(!_vm.$v.password.required && _vm.$v.password.$dirty)?_c('div',{staticClass:"invalid"},[_vm._v(" Please confirm your password! ")]):_vm._e(),(
                !_vm.$v.password.isPasswordValid &&
                _vm.$v.password.required &&
                _vm.$v.password.$dirty
              )?_c('div',{staticClass:"invalid"},[_vm._v(" Password length should be 6 to 24 and should have atleast 1 capital letter. ")]):_vm._e()]):_vm._e()]),(!_vm.messageStatus && !_vm.errorMessageStatus)?_c('div',{staticClass:"reset-password-btn-block"},[_c('button',{staticClass:"reset-password-btn",on:{"click":_vm.submit}},[_c('span',{staticClass:"signin-signup-btn-txt"},[_vm._v("Submit")])])]):_vm._e(),(_vm.messageStatus)?_c('p',{staticClass:"resend-email signin-link"},[_c('a',{staticClass:"sign-in-text",attrs:{"href":"/"}},[_vm._v("Sign in")]),_vm._v(" to start your journey on LeadersHum ")]):_vm._e(),(_vm.messageStatus)?_c('p',{staticClass:"redirect-message"},[_vm._v(" Please click sign in if you're not redirected in few seconds ")]):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }