import axios from "axios";

let headers = {};

function handleReponse(response, callback) {
  if (
    response &&
    response.data.apiResponseStatus &&
    response.data.apiResponseStatus.code === 1000
  ) {
    callback(response, "success");
  } else {
    callback(response, "error");
  }
}

function handleError(error, callback) {
  const originalRequest = error.config;
  let refreshToken = localStorage.getItem("refresh_token");
  // token expired
  if (error && error.response.status === 401) {
    axios
      .post(
        process.env.VUE_APP_HOST +
          "account/api/v1/refresh" +
          "?refreshToken=" +
          refreshToken
      )
      .then(ref => {
        if (ref.data.apiResponseStatus.code === 1000) {
          localStorage.setItem(
            "access_token",
            ref.data.responseObject.oauth2AccessToken.access_token
          );
          localStorage.setItem(
            "refresh_token",
            ref.data.responseObject.oauth2AccessToken.refresh_token
          );
          localStorage.setItem(
            "expires_in",
            ref.data.responseObject.oauth2AccessToken.expires_in
          );
          let d = new Date();
          d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();

          document.cookie =
            "AccessToken" +
            "=" +
            localStorage.getItem("access_token") +
            ";" +
            expires +
            ";domain=.peoplehum.com;path=/";

          originalRequest.headers.Authorization =
            "bearer " + window.localStorage.getItem("access_token");
          return axios.request(error.config).then(response => {
            callback(response, "success");
          });
        } else {
          window.localStorage.clear();
          window.location.href = "/";
        }
      });
  }
}

export default {
  getRequest(path, callback, params) {
    if (window.localStorage.getItem("access_token")) {
      headers.Authorization =
        "bearer " + window.localStorage.getItem("access_token");
    }
    if (params) {
      path =
        path +
        "?" +
        Object.entries(params)
          .map(value => {
            return value.join("=");
          })
          .join("&");
    }
    axios
      .get(path, { headers })
      .then(response => {
        handleReponse(response, callback);
      })
      .catch(error => {
        handleError(error, callback);
      });
  },
  postRequest(path,payload,callback,params,portalHeaders) {
    if (window.localStorage.getItem("access_token") && !portalHeaders) {
      headers.Authorization =
        "bearer " + window.localStorage.getItem("access_token");
    }
    if(portalHeaders){
      headers = portalHeaders;
    }
    if (params) {
      path =
        path +
        "?" +
        Object.entries(params)
          .map(value => {
            return value.join("=");
          })
          .join("&");
    }
    axios
      .post(path, payload, { headers })
      .then(response => {
        handleReponse(response, callback);
      })
      .catch(error => {
        handleError(error, callback);
      });
  },
  putRequest(path, callback, payload, params,portalHeaders) {
    if (window.localStorage.getItem("token") && !portalHeaders) {
      headers.Authorization =
        "bearer " + window.localStorage.getItem("access_token");
    }
    if(portalHeaders){
      headers = portalHeaders;
    }
    if (params) {
      path =
        path +
        "?" +
        Object.entries(params)
          .map(value => {
            return value.join("=");
          })
          .join("&");
    }
    axios
      .put(path, payload, { headers })
      .then(response => {
        handleReponse(response, callback);
      })
      .catch(error => {
        handleError(error, callback);
      });
  },
  deleteRequest(path, callback, params) {
    if (window.localStorage.getItem("access_token")) {
      headers.Authorization =
        "bearer " + window.localStorage.getItem("access_token");
    }
    if (params) {
      path =
        path +
        "?" +
        Object.entries(params)
          .map(value => {
            return value.join("=");
          })
          .join("&");
    }
    axios
      .delete(path, { headers })
      .then(response => {
        handleReponse(response, callback);
      })
      .catch(error => {
        handleError(error, callback);
      });
  }
};
