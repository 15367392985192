import axios from 'axios';
import SignInImage from "../../components/SignInImage/SignInImage.vue"
import Loader from "../../components/Loader/Loader.vue"
import SignInBackground from '../../components/SignInBackground/SignInBackground.vue'
import axiosCall from "../../api/axios";
export default {
  name: "DelegateChoice",
  components: {
    SignInImage,
    Loader,
    SignInBackground
  },
  data() {
    return {
      sucessMessageStatus: false,
      isLoading: false,
      errorMessage: "",
      image: "",
      influencers: null,
      influencerNames: [],
      searchedInfluencers: null,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      }
    };
  },
  mounted() {
    this.getAllInfluencers();
  },
  methods: {
    getAllInfluencers() {
      axiosCall.getRequest(process.env.VUE_APP_HOST + 'account/api/v1/assistant/influencers',(response,type)=> {
          if(type === "success"){
            this.influencers = response.data.responseObject;
            this.searchedInfluencers = response.data.responseObject;
            if (response.data.responseObject && response.data.responseObject.length > 0) {
              let res = response.data.responseObject;
              res.forEach((influencer, index) => {
                this.influencerNames = [...this.influencerNames, influencer.firstName + ' ' + influencer.lastName]
              })
            }
          }
        });
    },
    goToDashboard(influencerId, influencer) {
      localStorage.setItem("assistant", true);
      localStorage.setItem("influencerId", influencerId);
      localStorage.setItem("influencerProfileUrl", influencer.profileUrl);
      localStorage.setItem("influencerUserName", influencer.username);
      localStorage.setItem("lastName", influencer.lastName);
      localStorage.setItem("firstName", influencer.firstName);
      localStorage.setItem("LoggedIn_Assistant", false);
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/dashboard";
    },
    keyMonitor(event) {
      this.searchedInfluencers = [];
      var searchInput, filter, li, i, txtValue;
      searchInput = document.getElementById("search-influencers");
      filter = searchInput.value.toUpperCase();
      li = this.influencerNames;
      for (i = 0; i < li.length; i++) {
        txtValue = li[i];
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          this.searchedInfluencers=[...this.searchedInfluencers,this.influencers[i]]
        }
      }
    }
  }
};
