import axios from "axios";
import { required } from "vuelidate/lib/validators";
import axiosCall from "../../api/axios";

export default {
  name: "SubscribeFeedBack",
  data() {
    return {
      mutablesubscribe: false,
      emailId: null,
      subscribed: null,
      unsubscribed: false,
      a: false,
      feedbackSent: false,
      feedbackText: "",
      request: null,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      }
    };
  },
  props: ["subscribe", "feedback"],
  mounted() {
    if (this.subscribe == true) {
      this.setEmailId();
    }
    if (this.feedback == true) {
      window.scrollTo(0, 0);
    }
  },
  validations: {
    feedbackText: {
      required
    }
  },
  methods: {
    closeModal() {
      if (this.subscribe == true) {
        this.$emit("changeSubscribe", false);
      }
      if (this.feedback == true) {
        this.$emit("changeFeedback", false);
      }
    },
    setEmailId() {
      this.setCookie("User");
      if (localStorage.getItem("assistant") == "true") {
        this.request = {
          influencerId: localStorage.getItem("influencerId"),
          emailId: this.emailId
        };
        this.subscriptionCheck();
      } else {
        this.request = {
          emailId: this.emailId
        };
        this.subscriptionCheck();
      }
    },
    subscriptionCheck() {
      let checkUrl =
        process.env.VUE_APP_HOST +
        "account/api/v1/check-newsletter?mailId=" +
        this.request.emailId;
        axiosCall.getRequest(checkUrl,(response,type)=> {
            if(type === "success"){
              if (response.data.responseObject == "UNSUBSCRIBED") {
                let url =
                  process.env.VUE_APP_HOST +
                  "account/api/v1/subscribe-newsletter?mailId=" +
                  this.request.emailId;
                if (localStorage.getItem("assistant") == "true") {
                  url =
                    process.env.VUE_APP_HOST +
                    "account/api/v1/subscribe-newsletter?mailId=" +
                    this.request.emailId +
                    "&influencerId=" +
                    this.request.influencerId;
                }
                axiosCall.getRequest(url,(response,type)=> {
                    if(type === "success"){
                      this.subscribed = false;
                    window.onscroll = function() {
                    window.scrollTo(0, 0);
                  };
                  window.scrollTo(0, 0);
                  setTimeout(() => this.closeModal(), 3000);
                  setTimeout(() => (window.onscroll = function() {}), 3000);
                    }
                });
              }
              if (response.data.responseObject == "SUBSCRIBED") {
                this.subscribed = true;
                window.scrollTo(0, 0);
              }
            }
          });
    },
    unsubscribe() {
      let url =
        process.env.VUE_APP_HOST +
        "account/api/v1/unsubscribe-newsletter?mailId=" +
        this.request.emailId;
      if (localStorage.getItem("assistant") == "true") {
        url =
          process.env.VUE_APP_HOST +
          "account/api/v1/unsubscribe-newsletter?mailId=" +
          this.request.emailId +
          "&influencerId=" +
          this.request.influencerId;
      }
      axiosCall.getRequest(url,(response,type)=> {
          if(type === "success"){
            this.unsubscribed = true;
            window.scrollTo(0, 0);
            window.onscroll = function() {
            window.scrollTo(0, 0);
        };
        setTimeout(() => this.closeModal(), 3000);
        setTimeout(() => (window.onscroll = function() {}), 3000);
          }
        });
    },
    setCookie(cname) {
      let cookieValue;
      cname = "User";
      const cookieArr = document.cookie.split(";");
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (cname == cookiePair[0].trim()) {
          cookieValue = JSON.parse(decodeURIComponent(cookiePair[1]));
          this.emailId = cookieValue.mailId;
          return cookieValue;
        }
      }
    },
    sendFeedback() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let payload;
        if (localStorage.getItem("assistant") == "true") {
          payload = {
            influencerId: localStorage.getItem("influencerId"),
            message: this.feedbackText
          };
        } else {
          payload = {
            message: this.feedbackText
          };
        }
        axiosCall.postRequest(
          process.env.VUE_APP_HOST + "content/api/v1/feedback/api",
          payload,
          this.callback
        );
      }
    },
    callback(response, type) {
      if (type === "success") {
        this.feedbackSent = true;
        window.scrollTo(0, 0);
        window.onscroll = function() {
          window.scrollTo(0, 0);
        };
        setTimeout(() => this.closeModal(), 3000);
        setTimeout(() => (window.onscroll = function() {}), 3000);
      }
    }
  }
};
