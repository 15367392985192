import axios from "axios";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import { mapGetters } from "vuex";
import axiosCall from "../../api/axios";
import Loader from "../../components/Loader/Loader.vue";
import PreviewBlog from "../../components/PreviewBlog/previewBlog.vue";
import SideMenu from "../../components/SideMenu/SideMenu.vue";

export default {
  name: "dashboard",
  components: {
    SideMenu,
    PreviewBlog,
    Loader,
    Hooper,
    Slide
  },
  data() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token")
      },
      alert: {},
      commenter: {},
      influencerContentStatus: true,
      preview: false,
      id: "",
      contentType: "",
      followerCount: 0,
      viewCount: 0,
      weekFollowerCount: 0,
      weekViewCount: 0,
      trending: null,
      blogTitle: "",
      blogContentImage: "",
      blogMonthlyCount: "",
      videoTitle: "",
      videoContentImage: "",
      videoMonthlyCount: "",
      ebookTitle: "",
      ebookContentImage: "",
      ebookMonthlyCount: "",
      ebookMessageStatus: false,
      videoMessageStatus: false,
      blogMessageStatus: false,
      blogMessage: "",
      videoMessage: "",
      ebookMessage: "",
      isLoading: false,
      blogId: "",
      videoId: "",
      ebookId: "",
      commentEmptyMessage: "",
      commentEmptyStatus: false,
      alertEmptyMessage: "",
      alertEmptyStatus: false,
      parentId: "",
      newFollower: {},
      newFollowerEmptyMessage: "",
      newFollowerEmptyStatus: false,
      openMenu: false,
      window: {
        width: 0,
        height: 0
      }
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapGetters({
      getLoggedIn: "getLoggedIn",
      getUser: "getUser"
    })
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    let loginStatus = localStorage.getItem("LoggedIn_Status");
    if (!loginStatus) {
      this.$router.push("/");
    }
    this.getDashboard();
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    updatePreview: function(e) {
      this.preview = e;
    },

    getDashboard() {
      this.isLoading = true;
      let payload;
      var url = process.env.VUE_APP_HOST + "content/api/v1/dashboard";
      if (localStorage.getItem("assistant") == "true") {
        var inflncrId = localStorage.getItem("influencerId");
        url =
          process.env.VUE_APP_HOST +
          "content/api/v1/dashboard?influencerId=" +
          inflncrId;
      }
      axiosCall.getRequest(url, this.callback, payload);
    },

    callback(response, type) {
      if (type === "success") {
        let responseObject = response.data.responseObject;
        //NEW FOLLOWER
        if (responseObject.FOLLOWER.length === 0) {
          this.newFollowerEmptyMessage = "You have no new followers";
          this.newFollowerEmptyStatus = true;
        } else {
          this.newFollower = responseObject.FOLLOWER;
        }

        //ALERT
        if (responseObject.ALERT.length === 0) {
          this.alertEmptyMessage = "You have no alert message.";
          this.alertEmptyStatus = true;
        } else {
          this.alert = responseObject.ALERT;
        }
        // INSIGHTS
        this.followerCount = responseObject.COUNTER.followerCount;
        this.viewCount = responseObject.COUNTER.viewCount;
        this.weekFollowerCount = responseObject.COUNTER.weekFollowerCount;
        this.weekViewCount = responseObject.COUNTER.weekViewCount;

        // COMMENTS
        if (responseObject.COMMENT.length === 0) {
          this.commentEmptyMessage = "You have no latest comments.";
          this.commentEmptyStatus = true;
        } else {
          this.commenter = responseObject.COMMENT;
        }

        this.trending = responseObject.TRENDING;
        // TRENDING
        if (Object.keys(responseObject.TRENDING).length === 0) {
          this.blogMessageStatus = true;
          this.videoMessageStatus = true;
          this.ebookMessageStatus = true;
          this.blogMessage = "You have not uploaded any blog.";
          this.videoMessage = "You have not uploaded any video.";
          this.ebookMessage = "You have not uploaded any ebook.";
        } else {
          //blog
          if (this.trending.BLOG === null) {
            this.blogMessageStatus = true;
            this.blogMessage = "You have not uploaded any blog.";
          } else {
            this.blogTitle = this.trending.BLOG.title;
            this.blogContentImage = this.trending.BLOG.coverImageUrl;
            this.blogMonthlyCount = this.trending.BLOG.monthlyViewCount;
            this.blogId = this.trending.BLOG.id;
          }
          //video
          if (this.trending.VIDEO === null) {
            this.videoMessageStatus = true;
            this.videoMessage = "You have not uploaded any video.";
          } else {
            this.videoTitle = this.trending.VIDEO.title;
            this.videoContentImage = this.trending.VIDEO.coverImageUrl;
            this.videoMonthlyCount = this.trending.VIDEO.monthlyViewCount;
            this.videoId = this.trending.VIDEO.id;
          }
          //ebook
          if (this.trending.EBOOK === null) {
            this.ebookMessageStatus = true;
            this.ebookMessage = "You have not uploaded any ebook.";
          } else {
            this.ebookTitle = this.trending.EBOOK.title;
            this.ebookContentImage = this.trending.EBOOK.coverImageUrl;
            this.ebookMonthlyCount = this.trending.EBOOK.monthlyViewCount;
            this.ebookId = this.trending.EBOOK.id;
          }
        }
        this.isLoading = false;
      }
    },
    myRowClickHandler(item) {
      if (item.contentType != "EBOOK") {
        this.id = item.redirectId;
        this.contentType = item.contentType;
        this.preview = true;
      } else {
        axiosCall.getRequest(
          process.env.VUE_APP_HOST + "content/api/v1/url/" + item.redirectId,
          (response, type) => {
            if (type === "success") {
              window.open(response.data.responseObject, "_blank");
              
            }
          }
        );
      }
    },
    blogClickedHandler() {
      if (!this.blogMessageStatus) {
        this.id = this.blogId;
        this.contentType = "BLOG";
        this.preview = true;
      }
    },
    videoClickedHandler() {
      if (!this.videoMessageStatus) {
        this.id = this.videoId;
        this.contentType = "VIDEO";
        this.preview = true;
      }
    },
    ebookClickedHandler() {
      let payload;
      if (!this.ebookMessageStatus) {
        axiosCall.getRequest(
          process.env.VUE_APP_HOST + "content/api/v1/url/" + this.ebookId,
          this.callbackEbook,
          payload
        );
      }
    },
    callbackEbook(response, type) {
      if (type === "success") {
        window.open(response.data.responseObject, "_blank");
      }
    },
    pushClickHandler(item) {
      this.parentId = item.id;
      this.$router.push({
        name: "Comment",
        params: {
          parentId: this.parentId,
          itemObject: item,
          routeTransfer: true
        }
      });
    },
    openDropDownMenu() {
      //this.open = false;
      this.openMenu = true;
    },
    closeDropDownMenu() {
      //this.open = true;
      this.openMenu = false;
    },
    addBlog() {
      //this.$router.push({ name: 'Delegate' });
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/addcontent/BLOG";
    },
    addVideo() {
      //this.$router.push({ name: 'Delegate' });
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/addcontent/VIDEO";
    },
    addEbook() {
      //this.$router.push({ name: 'Delegate' });
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;
      window.location.href = baseUrl + "/addcontent/EBOOK";
    },
    menuClose() {
      this.closeDropDownMenu()
    },
  }
};
